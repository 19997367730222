const Header = () => {
    return (
        <section>
            <div className="text-center">
                <img alt='PROCESS' src="/static/frontlogo.png" />
                <h1>PROCESS</h1>
            </div>
        </section>
    );
};

export default Header;