

 const apptheme = {
    primaryColor: '#34769c',
    secondaryColor : '#FFFFFF',
    tertiaryColor : '#0086c5',
    quaternaryColor : '#063e57',
    quintenaryColor : '#44c485',
    senaryColor : '#339263',
    septenary : '#666666'

} 

export default apptheme
