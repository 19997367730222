import React, { useReducer, useContext } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { api } from '../../../services/api'
import ColorButton from '../../Buttons/ColorButton';
import DefaultModal from '../../Utils/DefaultModal';
import toast from 'react-hot-toast'
import dayjs from 'dayjs'
import { Box, Tooltip, TextField } from '@mui/material'
import validateData from './validateData';
import { externalComponent } from '../../AppRoutes';
import { NumbersOnly } from '../../Utils/IntegerOnly'
import { CnpfMask } from '../../Utils/MaskedInput'

const url = '/people/'

export const translate_permissions_keys = {
    'Can': '',
    'add': 'Adicionar',
    'change': 'Alterar',
    'delete': 'Excluir',
    'view': 'Ver'
}

const getTitle = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const submit = async (data, getData, setLoader, handleClose) => {

    setLoader(true)
    let res = null

    if (data.id)
        res = await api.patch(`${url}${data.id}/`, data)
    else
        res = await api.post(url, data)
    if (res.status === 201 || res.status === 200) {
        toast.success(`Pessoa ${res.status === 201 ? 'adicionada' : 'editada'} com sucesso`)
        getData()
        handleClose()
        setLoader(false)
        return
    }
    toast.error(`Pessoa não pôde ser ${!data.id ? 'adicionada' : 'editada.'} Motivo: `)
    setLoader(false)
}

export const ViewPeopleModel = ({ data, handleClose }) => {

    return (
        <DefaultModal
            title={
                `Informações gerais de ${data.name}`
            }
            content={
                <Box>
                    {data.alldata}
                </Box>
            }
            handleClose={handleClose}
        />
    )
}

const EditPeopleModal = ({ data, handleClose, permissions, reloadData }) => {

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        id: data.id ? data.id : 0,
        name: data.name ? data.name : '',
        cnpj: data.cnpj ? data.cnpj : '',
        status: data.status ? data.status : '',
        ultima_atualizacao: data.ultima_atualizacao ? data.ultima_atualizacao : '',
        phone_numbers: data.phone_numbers || ''
    })

    const validData = validateData(state)

    return (
        <>
            <DefaultModal
                title={
                    `${getTitle(state)} Pessoas`
                }
                handleClose={handleClose}
                content={
                    <>
                        <Box className='item-content-modal noborder' >
                            <Box className='item-profile-row'>
                                <TextField
                                    id="name"
                                    type="text"
                                    label="Nome"
                                    value={state.name}
                                    onChange={(e) => { setState({ 'name': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 255
                                    }}
                                />
                                <TextField
                                    id="cnpj"
                                    type="text"
                                    size='small'
                                    sx={{ backgroundColor: 'white' }}
                                    label="CNPJ"
                                    value={state.cnpj}
                                    onChange={(e) => { setState({ 'cnpj': NumbersOnly(e.target.value) }) }}
                                    InputProps={{
                                        inputComponent: CnpfMask
                                    }}
                                />
                            </Box>
                            <Box className='item-profile-row'>
                                <TextField
                                    id="status"
                                    type="text"
                                    label="Status"
                                    value={state.status}
                                    onChange={(e) => { setState({ 'status': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    disabled
                                    inputProps={{
                                        maxLength: 50,
                                        readOnly: true
                                    }}
                                />
                                <TextField
                                    id="phone_numbers"
                                    type="text"
                                    label="Telefones"
                                    value={state.phone_numbers}
                                    onChange={(e) => { setState({ 'phone_numbers': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 255,
                                    }}
                                />

                            </Box>

                            <Box className='item-profile-row'>
                                <TextField
                                    id="ultima_atualizacao"
                                    type="text"
                                    disabled
                                    label="Última atualização"
                                    value={dayjs(state.ultima_atualizacao).toDate().toLocaleString()}
                                    onChange={(e) => { setState({ 'ultima_atualizacao': dayjs(e.target.value).toDate().toLocaleString() }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50,
                                        readOnly: true
                                    }}
                                />
                                <TextField
                                    id="created_at"
                                    type="text"
                                    disabled
                                    label="Criação"
                                    value={dayjs(state.created_at).toDate().toLocaleString()}
                                    onChange={(e) => { setState({ 'created_at': dayjs(e.target.value).toDate().toLocaleString() }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50,
                                        readOnly: true
                                    }}
                                />
                            </Box>
                        </Box>
                    </>
                }
                action={
                    <>
                        <Tooltip placement="left" title={validData}>
                            <Box>
                                <ColorButton
                                    onClick={() => submit(state, reloadData, setLoader, handleClose)}
                                    disabled={validData}
                                >Salvar
                                </ColorButton>
                            </Box>
                        </Tooltip>
                    </>
                }
            />
        </>
    )
}

export default EditPeopleModal