import React, { useContext, useEffect, useState } from 'react'
import ColorButton from '../Buttons/ColorButton'
import apptheme from '../theme/default'
import { Box } from '@mui/material'
import '../Settings/styles.css'
import DefButton from '../Buttons/defbutton'
import ReplyIcon from '@mui/icons-material/Reply';
import { getSession } from '../../contexts/auth'
import { useWindowSize } from "@uidotdev/usehooks";
import { CalcWidthSize } from '../Settings'
import { externalComponent } from '../AppRoutes'

import Peoples from './Peoples'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import Scripts from './Scripts'
import SmartToyIcon from '@mui/icons-material/SmartToy';

import ExecutionLogs from './ExecutionLogs'
import DescriptionIcon from '@mui/icons-material/Description';

const MenuList = () => {

    const user = getSession()
    const permissions = user?.permissions

    return ({
        'peoples': {
            title: 'Pessoas',
            icon: (<PeopleAltIcon />),
            obj: (<Peoples />),
            permission: (permissions.view_people)
        },
        'scripts': {
            title: 'Scripts',
            icon: (<SmartToyIcon />),
            obj: (<Scripts />),
            permission: (permissions.view_scripts)
        },
        'execution_log': {
            title: 'Logs de Execução',
            icon: (<DescriptionIcon />),
            obj: (<ExecutionLogs />),
            permission: (permissions.view_executionlogs)
        },
    })
}

const Processos = () => {
    const { isMobile } = useContext(externalComponent)
    const [selectedTab, setSelectedTab] = useState()
    const size = useWindowSize();

    const menulist = MenuList()

    const calcSize = CalcWidthSize(size)

    const itens = Object.entries(menulist).filter(([key, value]) => value.permission)

    useEffect(()=>{
        window.backtobase = () => setSelectedTab()
    }, [])

    return (
        <>
            {!selectedTab ?
                <>
                    <Box className='gridBox' sx={{ gridTemplateColumns: `repeat(${calcSize}, minMax(0, 1fr))` }}>
                        {itens.map(([key, value]) => (
                            <ColorButton
                                onClick={() => setSelectedTab(key)}
                                sx={{ height: '8rem', flex: 1, visibility: !value.obj ? 'hidden' : 'visible' }}
                                fColor={apptheme.primaryColor}
                                sColor={apptheme.tertiaryColor}>
                                <Box className='flexbox'>
                                    {value.icon}
                                    {value.title}
                                </Box>
                            </ColorButton>

                        ))}
                    </Box>
                </>
                :
                <>
                    <Box className={`settings-back-button ${isMobile ? '': 'hidden'}`}>
                        <DefButton
                            onClick={() => setSelectedTab()}
                        >
                            <ReplyIcon />
                        </DefButton>
                    </Box>
                    {MenuList()[selectedTab].obj}
                </>
            }

        </>
    )
}

export default Processos