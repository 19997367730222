import React, { useState, useReducer, useContext } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { api } from '../../../services/api'
import ColorButton from '../../Buttons/ColorButton';
import DefaultModal from '../../Utils/DefaultModal';
import toast from 'react-hot-toast'
import { Box, Tooltip, TextField } from '@mui/material'
import validateData from './validateData';
import { externalComponent } from '../../AppRoutes';
import dayjs from 'dayjs'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TlsButton } from '../../Settings/Email';

const url = '/scripts/'

export const translate_permissions_keys = {
    'Can': '',
    'add': 'Adicionar',
    'change': 'Alterar',
    'delete': 'Excluir',
    'view': 'Ver'
}

const getTitle = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const submit = async (data, getData, setLoader, handleClose) => {

    setLoader(true)
    let res = null

    if (data.id)
        res = await api.patch(`${url}${data.id}/`, data)
    else
        res = await api.post(url, data)
    if (res.status === 201 || res.status === 200) {
        toast.success(`Script ${res.status === 201 ? 'adicionado' : 'editado'} com sucesso`)
        getData()
        setLoader(false)
        return
    }
    toast.error(`Script não pôde ser ${!data.id ? 'adicionado' : 'editado.'} Motivo: `)
    setLoader(false)
}


const EditScriptsModal = ({ data, handleClose, permissions, reloadData }) => {

    const { setLoader } = useContext(externalComponent)

    const [error, setError] = useState('')

    const [state, setState] = useReducer(lockedReducer, {
        id: data.id ? data.id : 0,
        name: data.name ? data.name : '',
        script: data.script ? data.script : '',
        schedule_time: data.schedule_time ? data.schedule_time : '',
        active: 'active' in data ? data.active : true,
        test_proxy: data.test_proxy || ''
    })

    const executeScript = async () => {

        setLoader(true)
        const res = await api.post(`/scripts/exec_script/`, state)
        setLoader(false)
        if (res.status === 200) {
            toast.success('RPA executado com sucesso!')
            return
        }
        setError(res.data)
        toast.error('Ocorreu um erro ao executar o RPA.')
    }

    const validData = validateData(state)

    return (
        <>
            <DefaultModal
                dialogProps={{ maxWidth: 'md' }}
                title={
                    `${getTitle(state)} Scripts`
                }
                handleClose={handleClose}
                content={
                    <>
                        <Box className='item-content-modal noborder' sx={{ overflow: 'hidden' }}>
                            <Box className='item-profile-row'>
                                <TextField
                                    id="name"
                                    type="text"
                                    label="Nome"
                                    value={state.name}
                                    onChange={(e) => { setState({ 'name': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />
                            </Box>
                            <Box className='item-profile-row'>
                                <TextField
                                    id="test_proxy"
                                    type="text"
                                    label="URL de Proxy"
                                    value={state.test_proxy}
                                    onChange={(e) => { setState({ 'test_proxy': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 255
                                    }}
                                />
                            </Box>
                            <Box className='item-profile-row' sx={{ display: 'flex' }}>
                                <TimePicker
                                    sx={{ flex: 1 }}
                                    id="schedule_time"
                                    label="Horário de execução"
                                    value={dayjs(state.schedule_time)}
                                    onChange={(v) => { setState({ 'schedule_time': dayjs(v) }) }}
                                    fullWidth={false}
                                    ampm={false}
                                    className='timepicker-small'
                                />
                                <Box className='email-tls-buttons' sx={{ flex: '.2 !important' }}>
                                    <TlsButton tls={state.active} onClick={() => { setState({ active: true }) }} >ATIVO</TlsButton>
                                    <TlsButton tls={!state.active} onClick={() => { setState({ active: false }) }}>INATIVO</TlsButton>
                                </Box>
                            </Box>
                            <Box className='item-profile-row textareascroll'>
                                <TextField
                                    label="Script"
                                    multiline
                                    fullWidth={false}
                                    rows={16}
                                    size="small"
                                    value={state.script}
                                    onChange={(e) => { setState({ 'script': e.target.value }) }}
                                    inputProps={{ maxLength: 5000 }}
                                />
                            </Box>
                        </Box>
                    </>
                }
                action={
                    <>
                        <Tooltip placement="left" title={validData}>
                            <Box>
                                <ColorButton
                                    sx={{ marginRight: '1rem' }}
                                    onClick={() => executeScript()}
                                    disabled={validData}
                                >Executar Script
                                </ColorButton>
                                <ColorButton
                                    onClick={() => submit(state, reloadData, setLoader, handleClose)}
                                    disabled={validData}
                                >Salvar
                                </ColorButton>
                            </Box>
                        </Tooltip>
                    </>
                }
            />
        </>
    )
}

export default EditScriptsModal