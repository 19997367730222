import { Box, Divider, List, ListItem, ListItemButton, ListItemText, Popover, Skeleton } from '@mui/material'
import React, { useContext, useEffect, useReducer, useRef } from 'react'
import { lockedReducer } from '../Utils/reducers'
import DefaultModal from '../Utils/DefaultModal'
import { externalComponent } from '../AppRoutes'
import ColorButton from '../Buttons/ColorButton'
import { MobileFilters } from './filters'
import useVisibility from '../Utils/isVisible'

const solveNames = (columns, title) => {
    return columns.filter(e => title.includes(e.field))
}

const solveValues = (column, row) => {
    const value = 'valueGetter' in column ? column.valueGetter({ row: row, value: row[column.field] }) : row[column.field]
    if ('renderCell' in column)
        return column.hideTitle ? (column.renderCell({row: row, value:value})) : <span>{column.headerName}: {(column.renderCell({row: row, value:value}))}</span>
    return column.hideTitle ? value : `${column.headerName}: ${value}` 
}

const VisibilityComponent = ({loadMore}) => {
    const [isVisible, currentElement] = useVisibility(0, 100)

    useEffect(()=>{
        if (isVisible){
            loadMore()
        }
    }, [isVisible, loadMore])

    return (
        <ListItem disableGutters disablePadding sx={{margin: 0}} ref={currentElement}>
            <Skeleton sx={{width: '100%', height: '100%'}}/>
        </ListItem>
    )
}

const MobileTable = ({ parent, setParent, onFilterModelChange=()=>{}, rows, columns, title=[], secondary=[[]], actions, icon, slots, getRowClassName=()=>{}, onPaginationModelChange=()=>{}}) => {

    const listRef = useRef()
    const {loader} = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        actions: null,
        show: false,
        anchorEl: null
    })

    const columnTitle = solveNames(columns, title)
    const columnSecundary = secondary.map(column => solveNames(columns, column))
    const columnActions = solveNames(columns, actions || 'actions')

    useEffect(()=>{
        setState({actions:null, show:false})
    }, [rows])

    return (
        <>
            {state.actions &&
                <DefaultModal
                    handleClose={() => setState({ actions: null })}
                    title='Ações'
                    content={columnActions.map(row => row.renderCell({ row: state.actions, mobile: true }))}
                />
            }
            {state.show &&
                <Popover
                    open={state.show}
                    anchorEl={state.anchorEl}
                    onClose={() => setState({ anchorEl: null, show: null })}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <MobileFilters parent={parent} setParent={setParent} onFilterModelChange={onFilterModelChange} columns={columns}/>
                </Popover>
            }
            <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'column', overflow:'hidden', height:'100%' }}>
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    {slots?.addComponent && <ColorButton sx={{ flex: 1 }} onClick={slots.addComponent}>Adicionar</ColorButton>}
                    {slots?.filterComponent && <ColorButton sx={{ flex: 1 }} onClick={()=>setState({anchorEl: listRef.current, show: !state.show})}>Filtros</ColorButton>}
                </Box>
                <List disablePadding ref={listRef} sx={{ width: '100%',bgcolor: 'background.paper', overflow:'auto' }}>
                    {rows.map(each => (
                        <>
                            <ListItem className={getRowClassName({'row':each})} disablePadding alignItems="flex-start" key={`mobile-list-row${each.id}`}>
                                <ListItemButton onClick={() => setState({ actions: each })}>
                                    {icon}
                                    {(title || secondary) &&
                                        <ListItemText
                                            primary={<span className='mobile-title-separator'>{columnTitle.map(e => <span >{solveValues(e, each)}</span>)}</span>}
                                            secondary={
                                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                    {columnSecundary.map(column => (
                                                        <span 
                                                        className='mobile-secundary-separator'
                                                        >{column.map(e => <span>{solveValues(e, each)}</span>)}</span>
                                                    ))}
                                                </Box>
                                            }

                                        />}

                                </ListItemButton>
                            </ListItem>
                            <Divider component="li" />
                        </>
                    ))}
                    {(parent.next && !loader) && <VisibilityComponent loadMore={()=>{
                        const [,searchParams] = parent.next.split("?")
                        const search = new URLSearchParams(searchParams)
                        const pageSize = Number(search.get('limit'))
                        const offset = Number(search.get('offset')) 
                        onPaginationModelChange({
                            pageSize: pageSize,
                            page: offset / pageSize
                        })
                    }}/>}
                </List>
            </Box>
        </>
    )
}

export const ResponsiveActionBox = ({ children }) => {
    const { isMobile } = useContext(externalComponent)
    if (isMobile) return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {children.filter(e => e).map(each => (
                <Box onClick={()=>each?.props?.children?.props?.onClick()}>
                    <span>{each?.props?.children}</span>
                    <span>{each?.props?.title}</span>
                </Box>
            ))}
        </Box>
    )

    return (
        <Box sx={{display:'flex', flex: 1, overflow:'auto'}}>
            {children}
        </Box>
    )
}

export default MobileTable